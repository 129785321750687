<!--
 * @Author: mulingyuer
 * @Date: 2021-04-19 15:18:01
 * @LastEditTime: 2021-06-15 14:05:49
 * @LastEditors: aleaner
 * @Description: popover弹窗修改
 * @FilePath: \saas-admin-vue\src\base\components\Popover\PopoverInputEdit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="popover-input-edit" :class="{nowrap:nowrap,active:visible}">
    <div class="content">
      <slot :value="value">{{value}}</slot>
    </div>
    <el-popover :placement="placement" :width="width" v-model="visible"
                trigger="manual" popper-class="input-edit-warp"
      @hide="onHide('ruleForm')" @show="onShow('ruleForm')">
      <div class="input-edit-content">
        <h3 class="title">{{title}}</h3>
        <div class="input-content">
          <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" size="medium"
            class="medium-form" @submit.native.prevent>
            <el-form-item prop="value">
              <el-input v-if="inputType==='number'" v-model.number="form.value" ref="autofocus" clearable>
              </el-input>
              <el-input v-else v-model="form.value" ref="autofocus" clearable></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="input-edit-footer">
          <el-button size="small" round @click="onCancel">{{cancelButtonText}}</el-button>
          <el-button size="small" type="primary" round @click="onSave('ruleForm')">{{confirmButtonText}}
          </el-button>
        </div>
      </div>
      <i class="icon-edit" :class="icon" slot="reference" @click="visible = true"></i>
    </el-popover>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "handlerValue",
  },
  props: {
    //需要修改的数据
    value: {
      type: [String, Number],
      required: true,
    },
    //显示的图标
    icon: {
      type: String,
      default: "el-icon-edit",
    },
    //是否不换行
    nowrap: {
      type: Boolean,
      default: false,
    },
    //宽度
    width: {
      type: Number,
      default: 300,
    },
    //显示的位置
    placement: {
      type: String,
      default: "top",
    },
    //标题
    title: {
      type: String,
      default: "重新编辑",
    },
    //确认按钮文字
    confirmButtonText: {
      type: String,
      default: "保存",
    },
    //取消按钮文字
    cancelButtonText: {
      type: String,
      default: "取消",
    },
    //表单类型
    inputType: {
      default: "number",
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ["string", "number"].includes(value);
      },
    },
    //自定义校验
    validator: {
      type: Object,
      //测试的示例
      // default: () => {
      //   return {
      //     // type: "integer",
      //     // min: 2,
      //     // message: "最小不能低于2的整数",
      //     // trigger: "blur",
      //   };
      // },
    },
    //自动获取焦点
    autofocus: {
      type: Boolean,
      default: false,
    },
    // 是否为必填
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let valueRules = [];
    if (this.required)
      valueRules.push({
        required: true,
        message: "数据不能为空",
        trigger: "blur",
      });
    if (this.validator) valueRules.push(this.validator);
    return {
      visible: false,
      form: {
        value: "",
      },
      rules: {
        value: valueRules,
      },
    };
  },
  methods: {
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //弹窗关闭回调
    onHide(formName) {
      this.form.value = "";
      this.resetForm(formName);
    },
    //弹窗显示回调
    onShow(formName) {
      this.resetForm(formName);
      this.form.value = this.value;
      //自动获取焦点
      if (this.autofocus) {
        this.$nextTick(() => {
          this.$refs.autofocus.focus();
        });
      }
    },
    //取消
    onCancel() {
      this.visible = false;
      this.$emit("cancel");
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$emit("handlerValue", this.form.value);
          this.$emit("confirm");
          this.visible = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.popover-input-edit {
  display: flex;
  align-items: center;
  max-width: 100%;
  &.nowrap {
    .content {
      min-width: 0;
      @include nowrap();
    }
  }
  // &:hover {
  //   .icon-edit {
  //     opacity: 1;
  //   }
  // }
  // &.active {
  //   .icon-edit {
  //     opacity: 1;
  //   }
  // }
  .icon-edit {
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    color: #3576ff;
    cursor: pointer;
    outline: none;
    padding: 8px 5px;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
      color: #306ae6;
    }
  }
}
</style>
<style lang="scss">
.input-edit-warp {
  padding: 19px 22px 22px;
  .input-edit-content {
    .title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 15px;
      text-align: center;
    }
    .medium-form .el-form-item--medium.el-form-item {
      margin-bottom: 22px;
    }
  }
  .input-edit-footer {
    text-align: center;
    .el-button {
      min-width: 70px;
      padding: 8px 13px;
    }
  }
}
</style>
